import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fuikit%2FBlogArticleBtn%2FblogArticleBtn.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VU226jMBB9z1fMy0qtVLOGBpJ1PmZl8ASmIrbXNoF01X9fGWg3pKRSH7CE53bOmRknv1O9l39eyxT%2BbgAapLoJAlLOfxw2AKVxCh1zUlHnBaSZHQ6bt03yESUac0Y3xlad88YJsIZ0QLfwy0aP0gzMN1KZXgC3A6TxyLZ2GP9cXcqHbfEE8ct3T8CT9HGR5XnO8gnTdHDgEbP0FqvAnAxkBKRF%2FjPlz9EQnNSeAhktwFhZUbgATzIPKD0y04VFMT5Tu6k%2BBwrgyX7hvx3NVipFuhaQ8Rup8kkk00aNztI9MGZac%2BktPUZwR6MD8%2FSKAtI8hs5X%2FdyRLeff55B%2F1Z4Fl90irhgjriHtVyDtJkgtaWTvgxO7eYtz5HyLEuAkXU16biD%2FWvvinngvywnZfQI%2Ba7kEmd0T%2BCrVfuqneWfhsJWBzriyFvm0LBHysY3D3ZBSqONdTyo0%2F2VZynRV7dc94GtDsKJDt9RBjunm4tMyX1nL0arI21ZeBBxbnEq1ODBFDquPvnWnkcVL5wMdL6wyOqAOAryVFbISQ48TT9lSrRkFPHkBPkgXDivPSS1tHLYid3g6bN7%2BATvYm3V9BAAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var articleWrapperRecipe = _7a468({defaultClassName:'_1n8aqzb1',variantClassNames:{shadow:{true:'_1n8aqzb2'}},defaultVariants:{},compoundVariants:[]});
export var articleWrapperStyle = '_1n8aqzb0';
export var authorImgStyle = '_1n8aqzb8';
export var authorNameStyle = '_1n8aqzb9';
export var descriptionStyle = '_1n8aqzb7';
export var heroImageStyle = '_1n8aqzb3';
export var indentStyle = _7a468({defaultClassName:'',variantClassNames:{xIndents:{true:'_1n8aqzb4'}},defaultVariants:{},compoundVariants:[]});
export var linkStyle = '_1n8aqzba';
export var tagStyle = '_1n8aqzb5';
export var titleStyle = '_1n8aqzb6';
export var wrapperLinkStyle = '_1n8aqzbb';