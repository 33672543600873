import { BlogArticleSchema } from "@/schema";
import Link from "next/link";
import React from "react";
import { resolveRef } from "@/core/sanityAPI/client-resolvers";
import { SanityKeyedReference } from "sanity-codegen";
import { FlexContainer } from "../container";
import { RemoteImage } from "../remote-image";
import { Typography } from "../Typography";

import {
  articleWrapperStyle,
  articleWrapperRecipe,
  heroImageStyle,
  tagStyle,
  titleStyle,
  descriptionStyle,
  authorImgStyle,
  authorNameStyle,
  indentStyle,
  wrapperLinkStyle,
} from "./blogArticleBtn.css";
import { clsx } from "../utils";

const DESCRIPTION_MAX_WORDS_NUMBER = 26;

const handleDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length <= DESCRIPTION_MAX_WORDS_NUMBER) {
    return description;
  }
  return description.split(" ").slice(0, DESCRIPTION_MAX_WORDS_NUMBER).join(" ") + "...";
};

interface BlogArticleBtnProps {
  articleRef: SanityKeyedReference<BlogArticleSchema>;
  isFeatured: boolean;
  addShadow?: boolean;
}

export const BlogArticleBtn = ({ articleRef, addShadow }: BlogArticleBtnProps) => {
  const article = resolveRef(articleRef);
  const author = resolveRef(article.author);
  const wrapperClass = clsx(articleWrapperRecipe({ shadow: addShadow }), articleWrapperStyle);
  const handleClick = (slug) => {
    window.location.href = `/blog/${slug}`;
  };
  return (
    <FlexContainer
      className={wrapperClass}
      key={article.slug.current}
      direction="column"
      alignItems="start"
      justifyContent="between"
    >
      <FlexContainer className={wrapperLinkStyle} onClick={() => handleClick(article.slug.current)}>
        <FlexContainer direction="column" alignItems="start" justifyContent="between">
          {article.heroImage && (
            <RemoteImage
              sizes={{
                mobile: "calc(100vw - 20px)",
                tablet: "calc(30vw - 20px)",
                desktop: "calc(20vw - 20px)",
              }}
              className={heroImageStyle}
              image={article.heroImage}
              alt={article.title}
            />
          )}
          <div className={indentStyle({ xIndents: addShadow })}>
            <div>
              {article.categories?.map((cat, i) => {
                const { name } = resolveRef(cat);
                return (
                  <Link key={i} href={`/blog?category=${name}`}>
                    <Typography className={tagStyle}>{`#${name} `}</Typography>
                  </Link>
                );
              })}
            </div>
            <Typography className={titleStyle} settings={{ tag: "p" }}>
              {article.title}
            </Typography>
            <Typography className={descriptionStyle}>{handleDescription(article.description)}</Typography>
          </div>
        </FlexContainer>
        <FlexContainer className={indentStyle({ xIndents: addShadow })}>
          <div className={authorImgStyle}>
            <RemoteImage image={author.image} fill />
          </div>
          <Typography className={authorNameStyle}>{author.name}</Typography>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
