import React from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { Typography } from "@/uikit/Typography";
import { BlogArticleBtn } from "@/uikit/BlogArticleBtn";

import { articlesWrapperStyle, titleStyle } from "./newsAndUpdates.css";

const NewsAndUpdatesBlock = Block("newsAndUpdates", (props) => {
  const { title, articles } = props;

  return (
    <BlockContainer defaultTheme="light" settings={props.settings} blockPaddingTop="large" blockPaddingBottom="large">
      <Typography.h4 className={titleStyle}>{title}</Typography.h4>
      <div className={articlesWrapperStyle}>
        {articles.map((article, i) => (
          <BlogArticleBtn key={i} articleRef={article} addShadow isFeatured={false} />
        ))}
      </div>
    </BlockContainer>
  );
});

export default NewsAndUpdatesBlock;
